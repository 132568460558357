<template>
  <div class="mb-[100px]">

    <!-- Page Title -->
    <div v-show="merchantData.is_self_signup && merchantData.name === 'Entrepreneur Merchant'" class="pb-6 px-2 mb-10" id="mainDiv">
      <div class="flex items-center justify-between pb-4 border-0 border-b border-solid border-gainsboro">
        <p class="font-medium md:text-[32px] text-2xl text-black">{{ $t('Complete Your Profile') }}</p>
        <span class="material-symbols-rounded cursor-pointer text-[32px] text-stone-cold" @click="redirectToDashboard()">close</span>
      </div>
      
      <!-- Page Content -->
      <div class="mt-6 grid md:grid-cols-3 grid-cols-2 gap-[16px]">
        <div class="col-span-2 flex flex-col gap-[16px]">
          <div class="border border-solid rounded-lg px-5 pt-6 pb-[38px] border-gainsboro">    
            <p class="text-black font-medium md:text-[20px] text-[16px]">{{ $t('Company Details') }}</p>
            <p class="text-black font-medium md:text-[14px] text-[12px] mt-6">{{ $t('Fill out your profile details to ship your first order') }}</p>
            <div class="grid gap-6 mt-4 md:grid-cols-2 grid-cols-1">

              <!-- Company Name Input -->
              <div>
                <shipblu-input v-validate="'required|max:20'" name="company name" :labelPlaceholder="$t('Company Name')" :required="true" autocomplete="nofill" v-model="merchant.name" type="text" class="w-full" />
                <p class="md:text-[12px] text-[10px] text-black mt-2">{{$t('Company Name will be displayed on the shipping label')}}</p>
                <p class="text-danger text-sm" v-show="errors.has('company name')">{{ $t('Company Name is required') }}</p>
              </div>

              <!-- Store URL Input -->
              <div>
                <shipblu-input v-validate="'required|url'" name="store url" :labelPlaceholder="$t('Website or Social Media Link')" :required="true" autocomplete="nofill" v-model="merchant.store_url" type="text" class="w-full" />
                <p class="text-danger text-[12px]" v-show="errors.has('store url')">{{ $t('Website or Social Media Link is required') }}</p>
              </div>

              <!-- Street and Building Inputs -->
              <div class="flex gap-3">
                <div class="w-2/3">
                  <shipblu-input v-validate="'required'" name="street and building" :labelPlaceholder="$t('Street and Building')" :required="true" autocomplete="nofill" v-model="merchant.address.line_1" type="text" class="w-full" />
                  <p class="text-danger text-sm" v-show="errors.has('street and building')">{{ $t('Street and Building are required') }}</p>
                </div>
                <div class="w-1/3">
                  <shipblu-input v-validate="'required'" name="flat no" :labelPlaceholder="$t('Flat No.')" :required="true" autocomplete="nofill" v-model="merchant.address.line_2" type="text" class="w-full" />
                  <p class="text-danger text-sm" v-show="errors.has('flat no')">{{ $t('Flat No. is required') }}</p>
                </div>
              </div>

              <!-- Zones Select -->
              <div>
                <shipblu-select v-validate="merchant.address.zone !== null ? '' : 'required'" :localize="true" :options="zones" optionLabel="name" inputId="input-zone" :required="true" :labelPlaceholder="$t('Zone')" name="zone" type="high_level" @selectedOptions="selectZoneOptions($event)"/>
                <p class="text-danger text-sm" v-show="errors.has('zone')">{{ $t('Zone field is required') }}</p>
              </div>

              <!-- Monthly Volume Input -->
              <div>
                <shipblu-input v-validate="'required|numeric'" name="monthly volume" :labelPlaceholder="$t('Monthly Volume')" :required="true" autocomplete="nofill" v-model="merchant.committed_volume" type="text" class="w-full" />
                <p class="text-danger text-sm" v-show="errors.has('monthly volume')">{{ $t('Monthly Shipping Volume is required') }}</p>
              </div>

              <!-- Industries Select -->
              <div>
                <shipblu-select :options="industries" :localize="true" v-validate="this.merchant.industry.value !== '' ? '' : 'required'" optionLabel="text" inputId="industry-input" :required="true" :labelPlaceholder="$t('Business Industry')" @selectedOptions="selectIndustry($event)" name="industry"/>
                <p class="text-danger text-sm" v-show="errors.has('industry')">{{ $t('Business Industry is required') }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Upload Image Input -->
        <div class="md:col-span-1 col-span-2 flex flex-col gap-6 border border-solid border-gainsboro rounded-lg px-5 py-6">
          <p class="font-medium md:text-[20px] text-[16px] text-black">{{ $t('Company Logo') }}</p>
          <div class="flex items-center border-2 dashed-border">
            <input type="file" @change="uploadImage" class="hidden" ref="fileInput" accept="image/*">
            <div              
              @click="$refs.fileInput.click()"
              @drop="handleDrop"
              @dragover="handleDragover"
              @dragenter="handleDragover"
              class="m-auto lg:py-16 py-13 cursor-pointer text-center">
              <feather-icon icon="UploadIcon" svgClasses="h-10 w-10 text-night-snow" class="block" />
              <p class="text-primary text-sm" @click.stop="$refs.fileInput.click()">{{$t('Drag and drop your image')}}</p>
              <p class="text-xs text-silver" @click.stop="$refs.fileInput.click()">{{$t('Supports .png/.jpeg/.jpg formats')}}</p>
              <div class="w-full m-auto text-center mb-3" v-if="progress < 100">
                <vs-progress :percent="progress" color="primary"></vs-progress>
              </div>
              <div v-else class="text-center mt-3">
                <p class="text-xs text-black">{{ imageName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Submit & Cancel Buttons  -->
    <div id="footerDiv" class="fixed bottom-0 z-[99992] pt-[24px] pb-[32px] flex justify-end gap-3 border-0 border-t border-solid border-gainsboro bg-layout">
      <vs-button class="sm:text-lg text-sm sm:flex-grow-0 flex-grow font-semibold px-6" color="#8599B3" @click="redirectToDashboard()">{{ $t('Cancel') }}</vs-button>
      <vs-button class="sm:text-lg text-sm sm:flex-grow-0 flex-grow font-semibold" @click="updateMerchant()">{{ $t('Submit') }}</vs-button>
    </div>
  </div>
</template>

<script>
import staticZones from '../../assets/utils/geo.json'
import common from '../../assets/utils/common'
import { sendRequest } from '../../http/axios/requestHelper'

export default {
  data () {
    return {
      zones: staticZones,
      industries: [],
      merchant: {
        industry: { text: '', value: '' },
        address: {
          line_3: '',
          line_1: '',
          line_2: '',
          zone: null
        }
      },
      merchantData: {},
      userMerchant: {},
      file_url: '',
      progress: 0,
      myProgress: 0,
      imageName: '',
      pickupPoint: {},
      returnPoint: {}
    }
  },
  watch: {
    '$route.params.lang' () {
      this.industries = common.getIndustries(this)
    },
    'progress' (val) {
      if (val === 95) {
        clearInterval(this.myProgress)
      }
    }
  },
  methods: {
    selectZoneOptions (value) {
      const zone = value[0]
      this.merchant.address.zone = zone
    },
    selectIndustry (value) {
      const industry = value[0]
      this.merchant.industry = industry
    },
    uploadImage () {
      const formData = new FormData()
      const file = this.$refs.fileInput.files[0]
      formData.append('old_file', undefined)
      formData.append('new_file', this.$refs.fileInput.files[0])
      this.progress = 0
      this.startFakeProgress()
      sendRequest(true, false, this, 'api/v1/upload-file/', 'patch', formData, true,
        (response) => {
          this.file_url = response.data.file_url
          this.progress = 100
          clearInterval(this.myProgress)
          this.imageName = file.name
        }
      )
    },
    updateMerchant () {
      this.$validator.validateAll().then(result => {
        if (result && this.merchant.address && this.merchant.address.zone && this.merchant.address.zone.id !== 284) {
          const merchantObj = {
            display_name: this.merchant.name,
            name:this.merchant.name,
            address: {
              id: this.merchantData.address.id,
              line_1: this.merchant.address.line_1,
              line_2: this.merchant.address.line_2,
              line_3: this.merchant.address.line_3,
              zone: this.merchant.address.zone.id
            },
            committed_volume: this.merchant.committed_volume,
            store_url: this.merchant.store_url,
            industry: this.merchant.industry.value
          }
          if (this.file_url !== '') {
            merchantObj.logo = this.file_url
          }
          sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/?company_details=true`, 'patch', merchantObj, true,
            (response) => {
              this.updateProfileIsCompleted()
              localStorage.removeItem('merchant')
              localStorage.setItem('merchant', JSON.stringify(response.data))
            }
          )
        }
      })
    },
    updateProfileIsCompleted () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/profile-completed/`, 'patch', {}, true,
        () => {
          this.updatePickupPoint()
        }
      )
    },
    updatePickupPoint () {
      const addressObj = {
        address: {
          id: this.pickupPoint.address.id,
          ...this.merchant.address
        }
      }
      addressObj.address.zone = this.merchant.address.zone.id
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/pickup-points/${this.pickupPoint.id}/`, 'patch', addressObj, true,
        () => {
          this.updateReturnPoint()
        }
      )
    },
    updateReturnPoint () {
      const addressObj = {
        address: {
          id: this.pickupPoint.address.id,
          ...this.merchant.address
        }
      }
      addressObj.address.zone = this.merchant.address.zone.id
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/return-points/${this.returnPoint.id}/`, 'patch', addressObj, true,
        () => {
          this.redirectToDashboard()
          this.merchant = {
            address: {}
          }
        }
      )
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isImage(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .png, .jpg suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
    },
    isImage (file) {
      return /\.(png|jpg)$/.test(file.name)
    },
    redirectToDashboard () {
      this.$router.push({
        name: 'merchant-dashboard'
      }).then(() => { this.$router.go(0) })
    },
    loadPickupPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/pickup-points/`, 'get', null, true,
        (response) => {
          this.pickupPoint = response.data.results[0]
        }
      )
    },
    loadReturnPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.userMerchant.merchant}/return-points/`, 'get', null, true,
        (response) => {
          this.returnPoint = response.data.results[0]
        }
      )
    },
    startFakeProgress () {
      this.myProgress = setInterval(() => {
        if (this.progress < 90) { 
          const increment = Math.max(1, Math.floor((95 - this.progress) / 10))
          this.progress += increment
        }
      }, 200)
    }
  },
  beforeCreate () {
    const merchant = common.checkMerchantInLocalStorage(this)
    merchant.then(results => {
      this.userMerchant = results.userMerchantData
      this.merchantData = results.merchantData
      if (!(this.merchantData.is_self_signup && this.merchantData.name === 'Entrepreneur Merchant')) {
        this.$router.push({
          name: 'merchant-dashboard'
        })
      }
    })
  },
  mounted () {
    const mainDiv = document.getElementById('mainDiv')
    const footerDiv = document.getElementById('footerDiv')
    const syncDimensions = () => {
      if (mainDiv && footerDiv) {
        footerDiv.style.width = `${mainDiv.offsetWidth}px`
      }
    }
    syncDimensions()
    const resizeObserver = new ResizeObserver(syncDimensions)
    if (mainDiv) {
      resizeObserver.observe(mainDiv)
    }
  },
  created () {
    this.industries = common.getIndustries(this)
    let counterIndustry = 0
    const updatedIndustries = this.industries.map((industry) => {
      counterIndustry++ 
      return {
        id: counterIndustry,
        ...industry
      }
    })
    this.industries = updatedIndustries
    const merchant = common.checkMerchantInLocalStorage(this)
    merchant.then(results => {
      this.userMerchant = results.userMerchantData
      this.merchantData = results.merchantData
      this.loadPickupPoints()
      this.loadReturnPoints()
    })
  }
}
</script>

